import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";
import { Router as RouteDefault } from 'react-router-dom';
import Layout from './components/Layout';
import Formulario from './pages/Formulario/';
import Consulta from './pages/Consulta/';
import NotFound from './pages/NotFound/index.js';
import GlobalStyle from './styles/global.ts';

class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <GlobalStyle />
                <Routes>
"                   <Route path="solicitacao/:chaveSolicitacao" element={<Formulario  />} />
                    <Route path="/" element={<Consulta />} />
                </Routes>
            </Layout>
        );
    }
}




export default App;


{/* <Routes>
"                   <Route path="pr124/solicitacao/:chaveSolicitacao" element={<Formulario  />} />
                    <Route path="/pr124/" element={<Consulta />} /> */}

                    // <Route path="solicitacao/:chaveSolicitacao" element={<Formulario  />} />
                    // <Route path="/" element={<Consulta />} />
