import axios from 'axios';
import config from '../config/config.json';

// Create an instance of axios with custom configuration
const api = axios.create({  
  baseURL: config.api.urlTeste,
  withCredentials: true // Ensure cookies are included in requests
});

api.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    console.error('Request Error', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.error('Response Error', error);

    // Check if error response is due to unauthorized (401) and if it hasn't already been retried
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshResponse = await api.post('/solicitacao/tokens');
        
        if (refreshResponse.status === 200) {
          // Retry the original request with the new token
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error('Token refresh error', refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
