import React from 'react';
import { Table } from 'react-bootstrap'
import { TBody } from './styles';

const ResultTable = ({ tableItems, tableColumn  }) =>{

    return <Table striped bordered hover responsive>
    <thead>
        <tr>
        {tableColumn.map(column =>
            <th>{column}</th> )}
        </tr>
    </thead>
        <TBody>
            {tableItems.map((item) =>
            <tr key={item}>
                <td>{item.sot}</td>
                <td>{item.notaFiscal}</td>
                <td>{item.cnpj}</td>
                <td>{item.dataSolicitacao}</td>
                <td>{item.statusSolicitacao}</td>
                <td>{item.acaoData}</td>
                <td>{item.acao}</td>
                <td>{item.acaoComentario}</td>
                <td>{item.usuario}</td>
                <td>{item.usuarioId}</td>
            </tr>
            )}
    </TBody>
  </Table>
}
export default ResultTable;
