import React, { Component } from 'react';

class Layout extends Component {
  static displayName = Layout.name;

    render() {
      return (  
        <div>      
            <div id='head' >
              <img src="https://upload.wikimedia.org/wikipedia/en/thumb/6/69/Vallourec_logo.svg/320px-Vallourec_logo.svg.png"></img>
            </div>
            <div id='title'>
              <h1>Controle de Aprovações de NF</h1>
            </div>
            <div id='content'>
              {this.props.children}
            </div>
            <br /><br /><br />
            <div>
              
            </div>
          </div>
        );
    }
}
export default Layout;
