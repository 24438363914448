import React, { Component } from 'react';
import validator from 'validator';
import api from '../../services/api';
import './style.css';


const actionsOptions = [
  { value: '1', label: 'Aprovada' },
  { value: '2', label: 'Aprovada com ressalva' },
  { value: '3', label: 'Reprovada' }
];

class Formulario extends Component {
    static displayName = Formulario.name;

    constructor(props) {
        super(props);
        const chaveSolicitacao = window.location.pathname.split('/')[2];

        this.state = {
            chaveSolicitacao: chaveSolicitacao,
            formData: {
              id: '',
              sot: '',
              cnpj: '',
              notaFiscal: '',
              dataSolicitacao: '',
              usuario: '',
              usuarioId: '',
              usuarioEmail: '',
              dataLiberacao: '',
              acao: '',
              acaoComentario: '',
              statusAprovacao: '',
              dataAprovacao: ''
            },
            loadings: false,
            loadingsMessage: null,
            formValidate: {
              usuario: true,
              usuarioMessage: null,
              usuarioId: true,
              usuarioIdMessage: null,
              usuarioEmail: true,
              usuarioEmailMessage: null,
              dataLiberacao: true,
              dataLiberacaoMessage: null,
              acao: true,
              acaoMessage: null,
              acaoComentario: true,
              acaoComentarioMessage: null
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
      await this.initializeToken();
  }

  async initializeToken() {
      try {
          const response = await api.post('/solicitacao/tokens');
      } catch (error) {
          console.error('Initial Token Fetch Error', error);
      }
  }

    handleChange(event) {
      this.setState(prevState => ({
        formData: {
            ...prevState.formData,
            [event.target.name]: event.target.value
        }
      }));
    }

    handleTextAreaChange(event) {
      this.setState(prevState => ({
        formData: {
            ...prevState.formData,
            [event.target.name]: event.target.value
        }
      }));
      this.autoGrow(event);
    };

    async handleSubmit(event){
      event.preventDefault();

      if(this.formValidate()){
        this.setState({ loadings: true });

        const retorno = await this.sendResponse();

        this.setState({ loadingsMessage: retorno.mensagem });
        this.setState({ loadings: false });
      }
    };

    async sendResponse() {
      debugger;
      const chave = this.state.chaveSolicitacao;
      const formData = this.state.formData;
      const data = {
        chaveSolicitacao: chave,
        idUsuarioAprovacao: formData.usuarioId,
        usuarioAprovacao: formData.usuario,
        emailUsuarioAprovacao: formData.usuarioEmail,
        status: parseInt(formData.acao),
        comentarioAprovacao: formData.acaoComentario,
        dataLiberacao: formData.dataLiberacao
      };
      const config = { headers: { 'Content-Type': 'application/json' } };

      let response = await api.put("solicitacao", data, config)
                        .then(function (response) {
                          return response.data;
                        })
                        .catch(function (error) {
                          return error.response.data;
                        });

      return response;
  }

    formValidate = () => {
      let formDataValidate = {
        usuario: true,
        usuarioMessage: null,
        usuarioId: true,
        usuarioIdMessage: null,
        usuarioEmail: true,
        usuarioEmailMessage: null,
        dataLiberacao: true,
        dataLiberacaoMessage: null,
        acao: true,
        acaoMessage: null,
        acaoComentario: true,
        acaoComentarioMessage: null
      };

      if(this.state.formData.usuario == null || this.state.formData.usuario === ''){
        formDataValidate.usuario = false;
        formDataValidate.usuarioMessage = 'Usuário é obrigatório.';
      }

      if(this.state.formData.usuarioId == null || this.state.formData.usuarioId === ''){
        formDataValidate.usuarioId = false;
        formDataValidate.usuarioIdMessage = 'Id do usuário é obrigatório.';
      }

      if(this.state.formData.dataLiberacao == null || this.state.formData.dataLiberacao === ''){
        formDataValidate.dataLiberacao = false;
        formDataValidate.dataLiberacaoMessage = 'Data de liberação é obrigatório.';
      }

      if(this.state.formData.usuarioEmail == null || this.state.formData.usuarioEmail === ''){
        formDataValidate.usuarioEmail = false;
        formDataValidate.usuarioEmailMessage = 'Email é obrigatório.'
      }
      else if(!validator.isEmail(this.state.formData.usuarioEmail)){
        formDataValidate.usuarioEmail = false;
        formDataValidate.usuarioEmailMessage = 'Email inválido.';
      }

      if(this.state.formData.acao == null || this.state.formData.acao === '' || this.state.formData.acao === 0){
        formDataValidate.acao = false;
        formDataValidate.acaoMessage = 'Ação é obrigatória.';
      }
      else{
        if(parseInt(this.state.formData.acao) > 1)
        {
          if(this.state.formData.acaoComentario == null || this.state.formData.acaoComentario === ''){
            formDataValidate.acaoComentario = false;
            formDataValidate.acaoComentarioMessage = 'Comentário obrigatório.'
          }
        }
      }

      this.setState({ formValidate: formDataValidate});

      return formDataValidate.usuario &&
        formDataValidate.usuarioId &&
        formDataValidate.usuarioEmail &&
        formDataValidate.dataLiberacao &&
        formDataValidate.acao &&
        formDataValidate.acaoComentario;
    }

    async componentDidMount() {
        this.setState({ loadings: true });
        let data = await this.getSolicitacao();

        if(data.mensagem != null) {
          this.setState({ loadingsMessage: data.mensagem });
        }
        else {
          this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                id: data.idSolicitacao,
                sot: data.sot,
                cnpj: data.cnpj,
                notaFiscal: data.notaFiscal,
                dataSolicitacao: data.dataSolicitacao,
                acao: '1'
            }
          }));
        }

        this.setState({ loadings: false });
    }

    async getSolicitacao() {

      debugger
      
      let data = await api.get("solicitacao/" + this.state.chaveSolicitacao)
                  .then(function (response) {
                      if(response.data.statusAprovacao !== 'Em andamento' && response.data.dataAprovacao != null)
                      {
                        return { mensagem: 'Solicitação já foi finalizada!'};
                      }
                      return response.data;
                  })
                  .catch(function (error) {
                    if (!error.response) {
                      // network error
                      return { mensagem: 'Error: Network Error'};
                    }
                    else {
                        return error.response.data;
                    }
                  });
        return data;
  };

  autoGrow(oField) {
      if (oField.scrollHeight > oField.clientHeight) {
          oField.style.height = oField.scrollHeight + "px";
      }
  }

  render() {
    return (
      this.state.loadings
      ? <div id="formMessage"> <label>Loading...</label></div>
      : this.state.loadingsMessage != null ?
          <div id="formMessage"><label>{this.state.loadingsMessage}</label></div>
          :
        <div id="form">
            <form onSubmit={this.handleSubmit}>
              <div>
                <label>SOT: </label>
                <input name="sot" value={this.state.formData.sot} readOnly />
              </div>

              <div>
                <label>CNPJ: </label>
                <input name="cnpj" value={this.state.formData.cnpj} readOnly />
              </div>

              <div>
                <label>Nota Fiscal: </label>
                <input name="notaFiscal" value={this.state.formData.notaFiscal} readOnly />
              </div>

              <div>
                <label>Data da Solicitacao: </label>
                <input name="dataSolicitacao" type="date" value={this.state.formData.dataSolicitacao} readOnly  />
              </div>

              <div>
                <label>Usuário: </label>
                <input name="usuario" value={this.state.formData.usuario} onChange={e => this.handleChange(e)}  />
                {!this.state.formValidate.usuario && <span className="error" style={ {color: '#f00' }}>{this.state.formValidate.usuarioMessage}</span>}
              </div>

              <div>
                <label>Id Vallourec: </label>
                <input name="usuarioId" value={this.state.formData.usuarioId} onChange={e => this.handleChange(e)}  />
                {!this.state.formValidate.usuarioId && <span className="error" style={ {color: '#f00' }}>{this.state.formValidate.usuarioIdMessage}</span>}
              </div>

              <div>
                <label>E-mail do Usuário: </label>
                <input name="usuarioEmail" value={this.state.formData.usuarioEmail} onChange={e => this.handleChange(e)}  />
                {!this.state.formValidate.usuarioEmail && <span className="error" style={ {color: '#f00' }}>{this.state.formValidate.usuarioEmailMessage }</span>}
              </div>

              <div>
                <label>Data de Liberação: </label>
                <input name="dataLiberacao" type="date" onChange={e => this.handleChange(e)} value={this.state.formData.dataLiberacao}  />
                {!this.state.formValidate.dataLiberacao && <span className="error" style={ {color: '#f00' }}>{this.state.formValidate.dataLiberacaoMessage}</span>}
              </div>

              <div>
                <label>Ação:  </label>
                <select name="acao" placeholder='Seleciona uma ação...' value={this.state.formData.acao}  onChange={e => this.handleChange(e)}>
                    {actionsOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
                {!this.state.formValidate.acao && <span className="error" style={ {color: '#f00' }}>{this.state.formValidate.acaoMessage }</span>}
              </div>

              <div>
                <label>Adicionar comentário: </label>
                <textarea name="acaoComentario" placeholder="Digite aqui..." value={this.state.formData.acaoComentario} onChange={e => this.handleTextAreaChange(e)}  />
                {!this.state.formValidate.acaoComentario && <span className="error" style={ {color: '#f00' }}>{this.state.formValidate.acaoComentarioMessage }</span>}
              </div>
                <br/>
                <button type="submit">Enviar</button>
            </form>
        </div>
    );
  }
}
export default Formulario;
