import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    outline: 0;
  }

  #root, body, html {
    -webkit-font-smoothing: antialiased;
    height: 100%;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    padding: 10px 0px 0px 0px;
  }

  a {
    text-decoration: none;
  }

  #content {
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  body, input, button {
    font-family: "Roboto",sans-serif;
    font-size: 14px;
  }

  .tooltip {
        position: absolute;
        z-index: 999;
        background: rgba(255, 242, 204, 0.9);
        padding: 10px;
        color: #473f3f;
        font-size: 0.875rem;
        max-width: 230px;
        border-radius: 4px;
        top: 0px;
        left: 0px;
    }


  button {
    cursor: pointer;
  }

`;
