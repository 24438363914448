import React, { Component } from 'react';
import './style.css';
import api from '../../services/api';
import * as Icon from 'react-bootstrap-icons';
import ResultTable from '../../components/Table';

class Consulta extends Component {
    static displayName = Consulta.name;

    constructor(props) {
        super(props);

        this.state = {
            valorPesquisa: '',
            tableData: [],
            loadings: false,
            loadingsMessage: null,
            tableColumns: ['Código SOT', 'Nota Fiscal', 'CNPJ',
                'Data da Solicitação', 'Status da Solicitação',
                'Data da Aprovação', 'Status da Aprovacao',
                'Comentário da Solicitação', 'Usuário', 'Id do Usuario' ]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        
           //  this.initializeToken();
        
    }

    async componentDidMount() {
        await this.initializeToken();
    }

    async initializeToken() {
        try {
            debugger
            const response = await api.post('/solicitacao/tokens');
        } catch (error) {
            console.error('Initial Token Fetch Error', error);
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleSubmit(event) {
        event.preventDefault();

        const valor = this.state.valorPesquisa;

        if (valor != null && valor !== '') {
            this.setState({ loadings: true });
            this.setState({ loadingsMessage: null });

            const data = await this.search(valor);

            if (data.length > 0) {
                let dataTable = [];

                data.forEach(item => (
                    dataTable.push({
                        id: item.idSolicitacao,
                        sot: item.sot,
                        cnpj: item.cnpj,
                        notaFiscal: item.notaFiscal,
                        dataSolicitacao: item.dataSolicitacao,
                        statusSolicitacao: item.statusSolicitacao,
                        usuario: item.usuarioAprovacao,
                        usuarioId: item.idUsuarioAprovacao,
                        usuarioEmail: item.emailUsuarioAprovacao,
                        acao: item.statusAprovacao,
                        acaoData: item.dataAprovacao,
                        acaoComentario: item.comentarioAprovacao
                    })
                ));

                this.setState({ tableData: dataTable });
            } else {
                if (data.mensagem != null) {
                    this.setState({ loadingsMessage: data.mensagem });
                }
            }
            this.setState({ loadings: false });
        }
    }

    async search(valor) {
        const chave = valor;

        let response = await api.get("solicitacao/consulta/" + chave)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error.response.data;
            });

        return response;
    }

    renderTable = () => {
        return <ResultTable tableItems={this.state.tableData} tableColumn={this.state.tableColumns} />;
    }

    render() {
        return (
            <div id="consulta">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input id='input' placeholder='Digite: Nota Fiscal, Código SOT ou CNPJ'
                            pattern='^[a-zA-Z0-9\-]+$' name="valorPesquisa" maxLength={70}
                            value={this.state.valorPesquisa}
                            onChange={e => this.handleChange(e)} />
                    </div>
                    <br />
                    <button type="submit"><Icon.Search size={18} /> </button>
                </form>
                <br />
                <br />
                {this.state.loadings
                    ? <div id="formMessage"> <label>Loading...</label></div>
                    : this.state.loadingsMessage != null ?
                        <div id="formMessage"><label>{this.state.loadingsMessage}</label></div>
                        : this.state.tableData.length > 0 ? this.renderTable() : this.state.loadingsMessage}
            </div>
        );
    }
}

export default Consulta;
